import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClientsSlider from "../component/ClientsSlider";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import parse from "html-react-parser";
import { API_URL, ASSET_URL } from "../constants";
import { Helmet } from 'react-helmet';

function About() {
  const [getData, setData] = React.useState(null);

  useEffect(() => {
    axios.get(API_URL + "about-pages").then((response) => {
      setData(response.data);
    });
    AOS.init();
  }, []);
  if (!getData) return null;

  return (
    <div>
      <Helmet>
        <title>About Us | Weldforce International</title>
      </Helmet>
      <div className="about-bg1">
        <section
          className="about-header"
          style={{
            backgroundImage: `url(${ASSET_URL + "/public/uploads/" + getData.data.banner
              })`,
          }}
        >
          <Container fluid p-0>
            <Row>
              <Col lg={12} md={12}>
                <div className="about-slider_text">
                  <h4 className=" orange-f border-white">ABOUT US</h4>
                </div>
                <p className="white-f">Home &gt; About Us</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9} md={9}>
                <div className="about-men_img">
                  {/* <img alt="Thumbnail"
                    src={
                      ASSET_URL +
                      "/public/uploads/" +
                      getData.data.thumbnail_image
                    }
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  /> */}
                  <div
                    data-aos="zoom-in"
                    className="ratio ratio-16x9 aos-init aos-animate"
                    style={{ maxWidth: '849px', margin: '0 auto' }}
                  >
                    <iframe
                      src="https://player.vimeo.com/video/829448206?h=f3d3a2194f&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;app_id=122963"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                      title="WeldForce Corporate Film 230523.mp4"
                    ></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="sec-padd border-bottomf0 mt-5">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <h5 className="about-main_header">ABOUT US</h5>
                <Col lg={5} md={12}>
                  <p className="about-main_header1">{getData.data.title}</p>
                </Col>
              </Col>
              <Col lg={8} md={8}>
                <p className="about-main_header2">
                  {parse(`${getData.data.description}`)}
                </p>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-product">
                  <div className="about-product_img">
                    <Card.Body>
                      <h6 className="about-main_header5">
                        We’re Committed To Quality
                      </h6>
                      <p className="about-main_header6">
                        Leader in the industry since
                      </p>
                      <p className="about-main_header7">
                        {" "}
                        {getData.data.industry_since}
                      </p>
                    </Card.Body>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-bluebg">
          <Container fluid p-0>
            <Row>
              <Col md={6} className="p-0">
                <img alt="middle"
                  src={ASSET_URL + "/public/uploads/" + getData.data.choose_banner}
                  className="about-middle_img"
                />
              </Col>
              <Col md={6} className="about-main_header10">
                <Col lg={8} md={12}>
                  <h3 className="about-main_header8">Why Choose Us</h3>
                </Col>
                {getData.data.choose_us &&
                  getData.data.choose_us.map((data2, index) => (
                    <Col md={12} className="pt-4">
                      <div className="pb-2">
                        <div className="about-high">
                          <img alt="about high"
                            src={ASSET_URL + "/public/uploads/" + data2.images}
                            className="about-high1"
                          />
                          <span className="about-IoLocationSharp2">
                            {" "}
                            {data2.title}
                          </span>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-main_header9">
          <Container>
            <ClientsSlider data={getData.brand} />
          </Container>
        </section>
      </div>
    </div>
  );
}

export default About;
