import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CiFacebook } from "react-icons/ci";
import { BsGlobe2, BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { API_URL } from "../constants";
import axios from "axios";
function Footer() {
  const [getData, setData] = React.useState(null);
  const [getProduct, setProduct] = React.useState(null);
  React.useEffect(() => {
    axios.get(API_URL + "contact-pages").then((response) => {
      setData(response.data);
    });
    axios.get(API_URL + "products").then((response) => {
      setProduct(response.data);
    });
  }, []);
  if (!getData) return null;
  if (!getProduct) return null;

  return (
    <div>
      <footer className="sec-padd-t bg-blue">
        <Container>
          <Row className="g-5">
            <Col lg={3} md={6}>
              <img src="../../assets/images/Logo.png" alt="logo"/>
              <p className="white-f mt-4 mb-3 normal-p">
                Weldforce International have commenced operations since 1995 in
                the field of manufacturing Resistance Welding Products.
              </p>
              <div>
                <a
                  target="_blank"
                  className="footer-facebook"
                  href={getData.data.facebook_link}
                >
                  <CiFacebook />
                </a>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <h4 className="border-white small-heading orange-f bold-f">
                Usefull Links
              </h4>
              <ul className="footer-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="about-us">About Us</Link>
                </li>
                <li>
                  <Link to="products">Products</Link>
                </li>
                <li>
                  <Link to="cad-center">CAD Center</Link>
                </li>
                <li>
                  <Link to="contact-us">Contact Us</Link>
                </li>
                {/* <li><Link href="/">Resources</Link></li> */}
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <h4 className="border-white small-heading orange-f bold-f">
                Our Products
              </h4>
              <ul className="footer-link">
                {getProduct.data &&
                  getProduct.data.map((data2, index) => (
                    <li>
                      <Link to="products">{data2.title}</Link>
                    </li>
                  ))}
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <h4 className="border-white small-heading orange-f bold-f">
                Contact Info
              </h4>
              <ul className="footer-link">
                <li className="link-flex1">
                  {" "}
                  <span>
                    <IoLocationSharp />
                  </span>{" "}
                  {getData.data.office_address}
                </li>
                <li className="link-flex1">
                  {" "}
                  <span>
                    <BsTelephoneFill />
                  </span>{" "}
                  {getData.data.first_mobile_no}
                  <br />
                  {getData.data.second_mobile_no}
                </li>
                <li className="link-flex1">
                  {" "}
                  <a
                    className="link-flex"
                    href={`mailto:${getData.data.email}`}
                  >
                    <span>
                      <GrMail />
                    </span>
                    {getData.data.email}
                  </a>{" "}
                </li>
                <li className="link-flex1">
                  {" "}
                  <a
                    target="_blank"
                    className="link-flex"
                    href={getData.data.website}
                  >
                    <span>
                      <BsGlobe2 />
                    </span>
                    {getData.data.website}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <section className="pt-3 pb-3 bg-orange">
          <p className="white-f text-center mb-0 normal-p">
            Copyright © 2023 Weldforce International - All Rights Reserved.
          </p>
        </section>
      </footer>
    </div>
  );
}

export default Footer;
