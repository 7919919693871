import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation,Autoplay } from "swiper/modules";
import { ASSET_URL } from "../constants";

function ClientsSlider(props) {
  const getData = props.data;

  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          450: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Navigation,Autoplay]}
        className="clients-slider"
      >
        {getData &&
          getData.map((data, index) => (
            <SwiperSlide>
              <img alt="client slider image" src={ASSET_URL + "/public/uploads/" + data.image} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default ClientsSlider;
