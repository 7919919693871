import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { CiFacebook } from "react-icons/ci";
import { ASSET_URL, API_URL } from "../constants";
import axios from "axios";

function Header() {
  const [getData, setData] = React.useState(null);

  React.useEffect(() => {
    axios.get(API_URL + "resource").then((response) => {
      setData(response.data);
    });
  }, []);
  if (!getData) return null;

  return (
    <div>
      <header sticky="top">
        <Navbar collapseOnSelect expand="lg" className="header-custom">
          <Container>
            <Navbar.Brand href="/">
              <img src="../../assets/images/Logo.png" alt="logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <Link to="/">Home</Link>
                <Link to="about-us">About Us</Link>
                <Link to="products">Products</Link>
                <Link to="cad-center">CAD Center</Link>
                <Link to="contact-us">Contact Us</Link>
                <NavDropdown
                  title=" Resources"
                  id="navbarScrollingDropdown "
                  className="download-nav"
                >
                  {getData.data &&
                    getData.data.map((data, index) => (
                      <NavDropdown.Item
                        target="_blank"
                        href={ASSET_URL + "/public/uploads/" + data.pdf}
                      >
                        {data.title}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
                <Link target="_blank" to={getData.conatct_data.facebook_link}>
                  <CiFacebook />
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
}

export default Header;
