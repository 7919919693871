import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation,Autoplay } from "swiper/modules";

import img1 from "../images/State1.webp"
import img2 from "../images/State2.webp"
import img3 from "../images/State3.webp"
import img4 from "../images/State4.webp"
import img5 from "../images/State5.webp"
import img6 from "../images/State6.webp"
import img7 from "../images/State7.webp"



function StateSlider() {
    const slider = [
        { id: 1, image: img1 },
        { id: 2, image: img4 },
        { id: 3, image: img2 },
        { id: 4, image: img6 },
        { id: 5, image: img3 },
        { id: 6, image: img7 },
        { id: 7, image: img5 },
    ];


    return (
        <>
            <div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                    breakpoints={{
                        450: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                    }}
                    navigation={true}
                    modules={[Navigation,Autoplay]}
                    className="slider_effect"
                >
                    {slider.map((data, index) => (
                        <SwiperSlide key={index}>
                            <img src={data.image} className="rounded " alt="slider image"/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default StateSlider