import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import parse from "html-react-parser";
import { API_URL, ASSET_URL } from "../constants";
import { Helmet } from 'react-helmet';

function Cad() {
  const [getData, setData] = React.useState(null);

  useEffect(() => {
    axios.get(API_URL + "cad-centers").then((response) => {
      setData(response.data);
    });
    AOS.init();
  }, []);
  if (!getData) return null;

  return (
    <div>
      <Helmet>
          <title>Cad Centers | Weldforce International</title>
        </Helmet>
      <div className="cad-bg1">
        <section
          className="cad-header"
          style={{
            backgroundImage: `url(${
              ASSET_URL + "/public/uploads/" + getData.data.banner
            })`,
          }}
        >
          <Container fluid p-0>
            <Row>
              <Col lg={12} md={12}>
                <div className="cad-slider_text">
                  <h4 className=" orange-f border-white">CAD CENTER</h4>
                  <p className="white-f">Home &gt; CAD Center</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5 mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={12} lg={6}>
                <h5 className="s-heading white-f text-center">How We Work</h5>
                <h3 className="cad-section1">Design & Detailing Center</h3>
                {/* <p className="m-heading orange-f pt-4 pb-2 text-center">
                  {getData.data.title}
                </p> */}
                <br/>
                <p className="white-f text-center">
                  {parse(`${getData.data.descriptions}`)}
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container>
            <Row>
              <Col md={12}>
                <img
                  alt="manufacutere image"
                  src={
                    ASSET_URL +
                    "/public/uploads/" +
                    getData.data.manufacturing_image
                  }
                   
                  data-aos="zoom-out"
                  data-aos-duration="2000"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container>
            <Row>
              <Col md={12}>
                <h3 className=" text-center orange-f heading6 semi-bold pb-4">
                  Our Engineering Services
                </h3>
                <Row className="justify-content-center g-4">
                  {getData.data.engineering_services &&
                    getData.data.engineering_services.map((data2, index) => (
                      <Col lg={4} md={6}>
                        <div className="cad-product-card">
                          <div className="cad-product_img">
                            <img
                              src={
                                ASSET_URL + "/public/uploads/" + data2.images
                              }
                              data-aos="fade-left"
                              data-aos-duration="2000"
                            />
                          </div>
                          <div className="text-center mt-4">
                            <h4 className="bold-f white-f">{data2.title}</h4>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Cad;
