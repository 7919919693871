import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col } from "react-bootstrap";
import { Navigation } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import ClientsSlider from "../component/ClientsSlider";
import { API_URL, ASSET_URL } from "../constants";
import axios from "axios";
import parse from "html-react-parser";
import { Helmet } from 'react-helmet';
import StateSlider from "./StateSlider";

function Home() {
  const [getData, setData] = React.useState(null);
  useEffect(() => {
    axios.get(API_URL + "home-pages").then((response) => {
      setData(response.data);
    });
    AOS.init();
  }, []);
  if (!getData) return null;
  var brand = getData.brand;

  return (

    <div>
      <Helmet>
        <title>Weldforce International</title>
      </Helmet>
      <div className="home-slider">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <video className="w-100"
              src={ASSET_URL + "/public/" + getData.data.video}
              muted
              autoPlay={1}
              loop={1}
            ></video>
          </SwiperSlide>
        </Swiper>
      </div>

      <section className="sec-padd home-we">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7} md={12}>
              <h5 className="s-heading white-f text-center pb-3">
                What We Offer
              </h5>
              <h3 className="orange-f heading8 text-center border-white">
                Resistance Welding Consumables
              </h3>
            </Col>
            <Col md={12}>
              <div className="sec-padd-t">
                <div className="row justify-content-center">
                  {getData.product &&
                    getData.product.map((data, index) => (
                      <div className="col-lg-4 col-md-6">
                        <div className="product-card">
                          <div
                            className="product-img "
                            data-aos="fade-right"
                            data-aos-duration="2000"
                          >
                            <img
                              src={ASSET_URL + "/public/uploads/" + data.image}
                            />
                          </div>
                          <div className="text-center mt-4">
                            <h5 className="product-name">{data.title}</h5>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sec-padd bg-banner1 border-bottomf">
        <Container>
          <Row className="g-5">
            <Col md={12}>
              <h5 className="s-heading white-f  ">How We Work</h5>
            </Col>
            <Col md={6}>
              <h3 className="orange-f heading7 border-white">
                Design & Detailing Center
              </h3>
              <p className="m-heading orange-f pt-4 pb-2">
                {getData.data.title}
              </p>
              <p className="white-f normal-text">
                {parse(`${getData.data.descriptions}`)}
              </p>
              <div
                className="containe-img border-righto"
                data-aos="slide-right"
                data-aos-duration="2000"
                style={{
                  backgroundImage: `url(${ASSET_URL + "/public/uploads/" + getData.data.image
                    })`,
                }}
              ></div>
            </Col>
            <Col md={6}>
              <h3 className="heading6 text-center orange-f pb-5">
                Our Engineering Services
              </h3>
              <Row className="g-3">
                {getData.data.engineering_services &&
                  getData.data.engineering_services.map((data, index) => (
                    <Col md={6}>
                      <div
                        className="overlay-box overlay-img1"
                        data-aos="zoom-left"
                        data-aos-duration="2000"
                      >
                        <h4 className="bold-f white-f">{data.title}</h4>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd bg-black">
        <Container>
          <Row className="justify-content-center ">
            <Col lg={7} md={12}>
              <h1 className="s-heading white-f text-center pb-3">About us</h1>
              {/* <h3 className="orange-f heading5 text-center border-white">
                {getData.about.title}
              </h3> */}
            </Col>
            <Col md={12}>
              <p className="white-f normal-text heading4 mt-4 text-center semi-bold">
                {parse(`${getData.about.description}`)}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="sec-padd bg-blue" >
        <Container>
          <Row className="justify-content-center ">
            <Col lg={7} md={12}>
            <p className="white-f m-heading1 semi-bold">
                  State-of-the-art machinery with
                </p>
                <h3 className="heading9   orange-f pb-5">
                  Well-Trained And Skilled Manpower
                </h3>


            </Col>
            <Col md={12}>
               <StateSlider />
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="pt-5 pb-5 bg-blue">
        <Container>
          <Row className="text-center g-0 align-items-center">
          <div
                className="text-center"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <p className="white-f m-heading1 semi-bold">
                  State-of-the-art machinery with
                </p>
                <h3 className="heading9 pb-4  orange-f">
                  Well-Trained And Skilled Manpower
                </h3>

              </div>
            <Col lg={12} md="12">
            <StateSlider />
            </Col>

          </Row>
        </Container>
      </section>

      {/* <section className=" bg-blue">
        <Container fluid>
          <Row className="text-center g-0 align-items-center">
            <Col lg={4} md="12">
              <div
                className="img-common-bg img-bg1"
                data-aos="zoom-out"
                data-aos-duration="2000"
                style={{
                  backgroundImage: `url(${ASSET_URL + "/public/uploads/" + getData.data.left_image
                    })`,
                }}
              ></div>
            </Col>
            <Col lg={4} md="12">
              <div
                className="p-5 text-center"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <p className="white-f m-heading1 semi-bold">
                  State-of-the-art machinery with
                </p>
                <h3 className="heading9   orange-f pb-5">
                  Well-Trained And Skilled Manpower
                </h3>
                <a className="orange-button white-hover" href="/">
                  Explore Products
                </a>
              </div>
            </Col>
            <Col lg={4} md="12">
              <div
                className="img-common-bg img-bg2"
                data-aos="zoom-out"
                data-aos-duration="2000"
                style={{
                  backgroundImage: `url(${ASSET_URL + "/public/uploads/" + getData.data.right_image
                    })`,
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="sec-padd sec-padd1">
        <Container>
          <Row className="g-5 justify-content-between align-items-center d-flex">
            <Col md={6} data-aos="fade-left" data-aos-duration="2000">
              <h5 className="s-heading orange-f pb-4 our_client">Our Clientele</h5>
              {/* <h3 className="orange-f heading10 border-white text-center">
              Our Clientele
              </h3> */}
            </Col>
            <Col md={6} data-aos="fade-right" data-aos-duration="2000">
              <img
                src="../../assets/images/map.png"
                alt=""
                className="img-height"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd-b sec-padd1">
        <Container>
          <ClientsSlider data={brand} />
        </Container>
      </section>
    </div>
  );
}

export default Home;
