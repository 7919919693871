import React, { useEffect } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { API_URL, ASSET_URL } from "../constants";
import { useForm } from "react-hook-form";
import { Helmet } from 'react-helmet';

function Contact() {
  const [getData, setData] = React.useState(null);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    var list = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      mobile: data.phone,
      comment: data.comment,
    };
    saveData(list);

    window.scrollTo({ top: 20, behavior: "smooth" });
  };

  function saveData(getArray) {
    axios({
      method: "post",
      url: API_URL + "enquery-form",
      data: getArray,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setMessage("success");
        document.getElementById("create-form").reset();
      })
      .catch(function (response) {
        setError(response.message);
      });
  }

  useEffect(() => {
    axios.get(API_URL + "contact-pages").then((response) => {
      setData(response.data);
    });
    AOS.init();
  }, []);
  if (!getData) return null;

  return (
    <div>
      <Helmet>
          <title>Contact US| Weldforce International</title>
        </Helmet>
      <div className="contact-bg1">
        {message === "success" ? (
          <div className="alert alert-success">
            Enquery submitted successfully!
          </div>
        ) : error === "Network Error" ? (
          <div className="alert alert-danger">{error}</div>
        ) : (
          ""
        )}

        <section
          className="contact-header"
          style={{
            backgroundImage: `url(${
              ASSET_URL + "/public/uploads/" + getData.data.banner
            })`,
          }}
        >
          <Container fluid p-0>
            <Row>
              <Col lg={12} md={12}>
                <div className="contact-slider_text">
                  <h4 className=" orange-f border-white">CONTACT US</h4>
                </div>
                <p className="white-f">Home &gt; Contact Us </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mt-5">
          <Container>
            <Row className="justify-content-center ">
              <Col lg={6} md={12}>
                <h5 className="contact-main_header">OUR CONTACTS</h5>
                <h3 className="contact-main_header1">
                  Let’s Start Working Together. Get In Touch!
                </h3>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mt-5">
          <Container>
            <Row className="justify-content-center  align-items-center text-center g-3">
              <Col lg={4} md={4}>
                <div className="contact-prod">
                  <div className="contact-product">
                    <div
                      className="contact-product_img"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <Card.Body>
                        <Card.Title className="contact-product_img1">
                          <Link className="contact-main_header14" href="#">
                            <span>
                              <IoLocationSharp />
                            </span>
                          </Link>
                        </Card.Title>
                        <h4 className="contact-main_header4">
                          Visit Office Address
                        </h4>
                        <p className="contact-main_header5">
                          {getData.data.office_address}
                        </p>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="contact-prod">
                  <div className="contact-product">
                    <div
                      className="contact-product_img"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <Card.Body>
                        <Card.Title className="contact-product_img1">
                          <Link className="contact-main_header14" href="/">
                            <span>
                              <BsTelephoneFill />
                            </span>{" "}
                          </Link>
                        </Card.Title>
                        <h3 className="contact-main_header4">Call</h3>
                        <p className="contact-main_header5">
                          {getData.data.first_mobile_no}
                          <br></br>
                          <span className="contact-main_header5">
                            {getData.data.second_mobile_no}
                          </span>
                        </p>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="contact-prod">
                  <div className="contact-product">
                    <div
                      className="contact-product_img"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <Card.Body>
                        <Card.Title className="contact-product_img1">
                          <Link className="contact-main_header14" href="/">
                            <span>
                              <GrMail />
                            </span>{" "}
                          </Link>
                        </Card.Title>
                        <h4 className="contact-main_header4">Write Email</h4>
                        <p className="contact-main_header5">
                          {getData.data.email}
                        </p>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mt-5">
          <Container fluid p-0>
            <Row>
              <Col md={6} className="p-0">
                {/* <img src='../../assets/images/Map1.png' ></img> */}
                <iframe
                  className="contact-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224567.72022927323!2d76.82493483041476!3d28.423160293840827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d582e38859%3A0x2cf5fe8e5c64b1e!2sGurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1691005788919!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                ></iframe>
              </Col>
              <Col md={6} sm={12} className="contact-main_header7">
                <Col lg={5} md={12} sm={12}>
                  <h3 className="contact-main_header8">Drop Us A Line</h3>
                </Col>
                <Col lg={7} md={12} sm={12}>
                  <p className="contact-main_header9">
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                </Col>
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  id="create-form"
                >
                  <div className="pb-3">
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="contact-main_header15"
                      name="name"
                      {...register("name", { required: "Name is Required" })}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                    {errors.name && (
                      <small className="text-dark">
                        Name Field is Required*
                      </small>
                    )}
                  </div>
                  <div className="pb-3">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      className="contact-main_header15"
                      {...register("email", {
                        required: "Email is Required",
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      })}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    {errors.email && (
                      <small className="text-dark">
                        Email Field is Required*
                      </small>
                    )}
                  </div>
                  <div className="pb-3">
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="contact-main_header15"
                      {...register("subject", {
                        required: "Subject is Required",
                      })}
                      onChange={(e) => setSubject(e.target.value)}
                    ></input>
                    {errors.subject && (
                      <small className="text-dark">
                        Subject Field is Required*
                      </small>
                    )}
                  </div>
                  <div className="pb-3">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone"
                      className="contact-main_header15"
                      {...register("phone", {
                        required: "Phone is Required",
                      })}
                      onChange={(e) => setSubject(e.target.value)}
                    ></input>
                    {errors.phone && (
                      <small className="text-dark">
                        Phone Field is Required*
                      </small>
                    )}
                  </div>

                  <div className="pb-4">
                    <textarea
                      type="text"
                      name="comment"
                      placeholder="Your comment"
                      className="contact-main_header15"
                      rows={3}
                      {...register("comment", {
                        required: "Your comment is Required",
                      })}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    {errors.comment && (
                      <small className="text-dark">
                        Your comment Field is Required*
                      </small>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="contact-main_header10">
                      SEND A MESSAGE
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="contact-main_header13">
          <Container>
            <Row>
              <Col md={6} sm={12} data-aos="zoom-in" data-aos-duration="2000">
                <Col lg={7} sm={12}>
                  <h3 className="contact-main_header11">
                    Better yet, see us in person!
                  </h3>
                </Col>
                <Col lg={8}>
                  <p className="contact-main_header12">
                    We love our customers, so feel free to visit during normal
                    business hours.
                  </p>
                </Col>
              </Col>
              <Col md={6}>
                <img
                  src="../../assets/images/Work.png"
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Contact;
