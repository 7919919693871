import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import parse from "html-react-parser";
import { API_URL, ASSET_URL } from "../constants";
import { Helmet } from 'react-helmet';

function Product() {
  const [getData, setData] = React.useState(null);

  useEffect(() => {
    axios.get(API_URL + "products").then((response) => {
      setData(response.data);
    });
    AOS.init();
  }, []);
  if (!getData) return null;

  return (
    <div>
      <Helmet>
          <title>Products | Weldforce International</title>
        </Helmet>
      <div className="product-bg1">
        <section className="product-weldheader">
          <Container fluid>
            <Row>
              <Col lg={12} md={12}>
                <div className="product-slider_text">
                  <h4 className=" orange-f border-white">Products</h4>
                </div>
                <p className="white-f">Home &gt; Products</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="sec-padd mt-2">
          <Container>
            <Row className="g-5">
              <Tab.Container id="left-tabs-example" defaultActiveKey="seventh">
              <Col md={4}>
                    <div className="product-btmbdr">
                      <div className="product-main_body">
                        <Nav
                          variant="pills"
                          className="flex-column product-tab"
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="seventh">
                              <div className="product-product_item br-0">
                                OUR PRODUCTS
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          {getData.data &&
                            getData.data.map((data, index) => (
                              <Nav.Item>
                                <Nav.Link eventKey={index}>
                                  <div className="product-product_item br-0">
                                    {data.title}
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                        </Nav>
                        <div className="body_part">
                          <div className="text-center">
                            <img
                              src="../../assets/images/Welforce.webp"
                              className="product-welforce_img"
                            />
                          </div>
                          <div className="product-product_item2">
                            <a className="product-cata"
                              target="_blank"
                              href={
                                ASSET_URL +
                                "/public/uploads/" +
                                getData.conatctData.pdf_catalog
                              }
                            >
                              PDF Catalog
                            </a>
                            <p>Excellence In Resistance Welding</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <Tab.Content>
                      <Tab.Pane eventKey="seventh">
                        <Row>
                          <h6 className="product-what">WHAT WE OFFER</h6>
                          <h5 className="product-intern">
                            Weldforce International Product Catalog
                          </h5>
                          <h6 className="product-leader">
                            Leader in the industry since 1995
                          </h6>
                          {getData.data &&
                            getData.data.map((data, index) => (
                              <Col md={6}>
                                <div className="product-product-card">
                                  <div
                                    className="product-product-img"
                                    data-aos="fade-right"
                                    data-aos-animation="2000"
                                  >
                                    <img
                                      src={
                                        ASSET_URL +
                                        "/public/uploads/" +
                                        data.image
                                      }
                                    />
                                  </div>
                                  <div className="text-center mt-4">
                                    <p className="product-product-name">
                                      {data.title}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </Tab.Pane>
                      {getData.data &&
                        getData.data.map((data, index) => (
                          <Tab.Pane eventKey={index}>
                            <h6 className="product-what">OUR PRODUCTS</h6>
                            <h5 className="product-intern1">{data.title}</h5>
                            <div className="product-product-card mt-5">
                              <div
                                className="product-product-img"
                                data-aos="fade-left"
                                data-aos-animation="2000"
                              >
                                <img
                                  src={
                                    ASSET_URL + "/public/uploads/" + data.image
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-center mt-4">
                              <p className="product-product-name1">
                                {parse(`${data.descriptions}`)}
                              </p>
                            </div>
                            {/* <div className="text-center">
                          <p className="product-product-name1">
                            We are the manufacturer & consistent supplier to OEMs
                            & their Tier-1 companies.
                          </p>
                        </div> */}
                          </Tab.Pane>
                        ))}
                    </Tab.Content>
                  </Col>
              </Tab.Container>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default Product;
