import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
import About from "./component/About";
import Cad from "./component/Cad";
import Contact from "./component/Contact";
import Product from "./component/Product";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/cad-center" element={<Cad />} />
        <Route path="/products" element={<Product />} />
        
       </Routes>
      <Footer />
    </Router>
  );
}

export default App;
